(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("xmlbuilder"));
	else if(typeof define === 'function' && define.amd)
		define(["xmlbuilder"], factory);
	else if(typeof exports === 'object')
		exports["RSSParser"] = factory(require("xmlbuilder"));
	else
		root["RSSParser"] = factory(root["xmlbuilder"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_xmlbuilder__) {
return 